import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import {
  useTasSubscription,
  useTaskUpdateSubscription,
  useTaskActionSubscription,
  useVideoReviewCompleteSubscription,
} from 'pages/ManagementPortal/hooks';
import NoData from 'common/NoData';
import AttentionProvider from 'contexts/AttentionContext';
import TaskItem from './components/TaskItem';
import AttentionPopover from './components/AttentionPopover';
import AssetDetails from './components/AssetDetails';
import { authContext } from 'contexts/AuthContext';
import { ROLES } from 'constants/role';

const ProjectDetailContent = ({
  parsedProjectId,
  pmEditorSelectedPlan,
  projectData: {
    id,
    sections = [],
    projects,
    broker,
    projectName,
    asanaProjectId,
    isNewTemplate,
    productType,
    projectUuid,
    ...rest
  },
  refetch,
}) => {
  const taskSubscription = useTasSubscription();

  const taskActionSubscription = useTaskActionSubscription();

  const taskUpdateSubscription = useTaskUpdateSubscription();

  const videoReviewCompleteSubscription = useVideoReviewCompleteSubscription();

  const { auth } = useContext(authContext);

  useEffect(() => {
    if (
      (taskUpdateSubscription &&
        parsedProjectId === taskUpdateSubscription.projectId) ||
      taskSubscription ||
      taskActionSubscription ||
      parsedProjectId === videoReviewCompleteSubscription?.data?.projectId
    ) {
      refetch();
    }
  }, [
    taskUpdateSubscription,
    taskSubscription,
    taskActionSubscription,
    videoReviewCompleteSubscription,
  ]);

  return (
    <>
      {!pmEditorSelectedPlan && (
        <div className="collapse-selected-table mb-4">
          <AssetDetails
            detail={{
              ...rest,
              broker,
              projectName,
              productType,
              projectUuid,
            }}
          />
        </div>
      )}
      {/* {isBronze ? (
        <DetailsTable />
      ) : ( */}
      <>
        <Table
          bordered
          className="project-detail-heading-table mt-2 d-inline-table"
        >
          <thead>
            <tr>
              <th>Task Name &nbsp; &nbsp;&nbsp;</th>
              <th>Completed on</th>
              <th>Status</th>
              <th>Alert</th>
              <th style={{ width: '20%' }}>
                {auth?.data?.role === ROLES.VIDEOMS_PM ? 'Credentials' : null}
              </th>
            </tr>
          </thead>
        </Table>
        <AttentionProvider>
          <>
            {sections.length ? (
              sections.map((section, index) => {
                if (!section) return null;
                const { sectionName } = section;
                return (
                  <TaskItem
                    key={`${sectionName}_${index + 1}`}
                    asanaProjectId={asanaProjectId}
                    isNewTemplate={isNewTemplate}
                    productType={productType}
                    {...section}
                    refetch={refetch}
                    planName={rest?.plan}
                  />
                );
              })
            ) : (
              <NoData customMessage="No sections found!!" />
            )}
            <AttentionPopover />
          </>
        </AttentionProvider>
      </>
      {/* )} */}
    </>
  );
};

export default ProjectDetailContent;
